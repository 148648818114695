import { ENABLE_SHIFT_TIME_RECEPTION } from '@/constants/feature-flags';
import { WEDDING_RESEPSI_TIME } from '@/constants/index';
import useQueryString from './useQueryString';

// Sesi 1: Holy Matrimony only tanpa gift 
// Sesi 2: Holy Matrimony & Reception tanpa gift 
// Sesi 3: Holy Matrimony & Reception dengan gift


export const SHIFT_LIST = [
  {
    value: '1',
    shift: '19.00 WIB',
    name: 'Sesi 1 (Holy Matrimony only tanpa gift)',
  },
  {
    value: '2',
    shift: '19.00 WIB',
    name: 'Sesi 2 (Holy Matrimony & Reception tanpa gift)',
  },
  {
    value: '3',
    shift: '19.00 WIB',
    name: 'Sesi 3 (Holy Matrimony & Reception dengan gift)',
  },
];

function generateShift(guestShift) {
  const { getQueryValue } = useQueryString();
  let shift = WEDDING_RESEPSI_TIME;

  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  if (guestShift) {
    shift = SHIFT_LIST.find((s) => s.value === guestShift.toString());
  } else {
    shift = SHIFT_LIST.find((s) => s.value === getQueryValue('shift'));
  }

  return shift ? shift.shift : 'Shift not found';
}

export default generateShift;
